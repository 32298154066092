.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px; 
    padding: 20px; 
  }
  
  .about-text {
    flex: 1; 
    margin: 0 10px;
  }

  .about-logos {
    width: 80px;
    height: 80px;
  }
  
  .portrait-photo {
    max-width: 350px; 
    height: auto;
  }

   @media (max-width: 768px) {
    .about-container {
      flex-direction: column;
      align-items: center; 
    }
    .portrait-photo {
      margin: 10px 0;
      max-width: 300px; 
    }
  }