body {
  max-width: 1200px;
  margin: 0 auto; 
  padding: 0 20px;
  box-sizing: border-box; 
}

a {
  text-decoration: none; 
  color: inherit; 
}

a:hover {
  text-decoration: none; 
  color: inherit; 
}

.description-toggle {
  cursor: pointer;
  display: inline-flex;
  text-align: left;
  font-weight: bold;
  transition: color 0.3s;
}

.description-title {
  font-weight: bold;
  font-size: large;
}

.caret {
  margin-left: 5px;
  transition: transform 0.3s;
}

.description-content {
  display: none; 
  margin-top: 10px;
}

.description-content.show {
  display: block; 
}

.caret.rotate {
  transform: rotate(180deg); 
}

.portfolio-container {
  display: flex;
  gap: 40px; 
  justify-content: flex-start;
  flex-wrap: wrap; 
}


.resume-container {
  display: flex;
  /* gap: 40px;  */
  justify-content: flex-start;
  flex-wrap: wrap; 
}

.thumbnail {
    width: 400px;
    height: 400px;
    flex: 1 1 calc(33.33% - 20px); 
    max-width: 400px; 
    height: auto; 
    position: relative;
    margin: 10px; 
  }
  
  .thumbnail img, .docs-thumb img {
    width: 400px;
    height: 400px;
    object-fit: cover;
    filter: grayscale(100%) contrast(150%);
    transition: filter 0.5s, transform 0.3s;
  }

  .thumbnail p {
    text-align: left; 
    margin-top: 10px; 
    transition: color 0.5s;
  }

  .thumbnail:hover img,
.thumbnail:focus img, 
.docs-thumb:hover img, 
.docs-thumb:focus img{
  filter: grayscale(0%); /* Removes black/white on hover */
  transform: scale(1.05); /* enlarges thumbnail on hover */
}

.docs-container {
  display: flex; 
  justify-content: flex-start;
  flex-wrap: wrap;  
  gap: 20px; 
}

.docs-thumb {
  flex: 1 1 calc(33.33% - 20px);
  max-width: 400px; 
  margin: 10px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.docs-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  object-position: top left; 
  transition: filter 0.5s, transform 0.3s;
}

@media (max-width: 768px) {
  .docs-container {
    justify-content: center;
    flex-direction: column; /* Stack thumbnails vertically */
    align-items: center;  
  }
  
  .docs-thumb {
    width: 100%; /* Full width on mobile */
    max-width: 300px; /* Max width for mobile */
    justify-content: center; /* Center content */
  }

  h2 {
    text-align: center; 
  }

  .thumbnail p {
    text-align: center; 
  }

  .portfolio-container, 
  .resume-container {
    justify-content: center;
    flex-direction: column; 
    align-items: center;  
  }

  .thumbnail {
    justify-content: center;
    width: 100%; 
    max-width: 300px;
  }
}
