@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

body {
  font-family: 'Avenir Next', Montserrat, sans-serif;
  max-width: 1400px;
  height: 100%;
}

.back-button-container {
  text-align: left; 
  width: 100%;
}

.back-button {
  background-color: white; 
  color: gray;
  border: none;
  padding: 10px 15px;
  margin-bottom: 20px; 
  cursor: pointer;
  font-size: 1.5rem;
  border-radius: 5px;
  outline: none; 
}

.back-button:focus {
  outline: none; 
}
/* .windows body {
  /* font-family: 'Segoe UI', 'Calibri', Arial, sans-serif; */

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  min-height: 90vh; 
  text-align: center;
  max-width: 1200px;
  margin: 0 auto; 
  padding: 0 20px;
}

.home-link {
  text-align: center;
  font-size: 1rem;
  margin-top: auto; 
}

.home-link a {
  color: #5e503f; 
  text-decoration: none; 
}

.home-link a:hover {
  text-decoration: underline; 
}

/********************************************* */

h2, h3 {
  text-align: left;
}

p {
  text-align: left;
}

main {
  flex: 1;
}

/* ********************* FOOTER *********************** */
.about-logos {
  margin: 0 3px;
  width: 4rem !important;
  height: auto !important;
}

.footer {
  background-color: White;
  color: #333;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
  margin-top: auto;
}